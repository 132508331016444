.page {
  padding: 2rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2rem;
}

.pageTitle {
  font-size: 24px;
  line-height: 1;
  font-weight: 700;
}

.tabWrapper {
  padding-bottom: 16px;
  margin-left: -16px;
}

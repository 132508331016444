.privacyPolicy {
  max-width: 960px;
  font-size: 15px;
  margin: auto;
  line-height: 1.5;
  width: 100%;
  color: #232323;
}

.pageHeading {
  font-size: 2rem;
  font-weight: 900;
  color: #121212;
  padding: 1.5rem 0;
}

.modifiedOn {
  font-size: 0.875rem;
  opacity: 0.7;
  padding-bottom: 3rem;
}

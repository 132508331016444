.layout {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.settingsSection {
  grid-column: 1/6;
}

.formWrapper {
  display: grid;
  gap: 1.5rem;
}

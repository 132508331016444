.wrapper {
  position: relative;
}

.browserWindow {
  padding: 8px;
  background: #dcdcdc;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  gap: 8px;
}

.browserWindowDot {
  height: 12px;
  width: 12px;
  background-color: #ababab;
  border-radius: 50%;
  display: inline-block;
}

.preview {
  border: solid 1px #dcdcdc;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.layout {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
}

.settingsSection {
  padding: 1rem;
}

.previewSection {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}

.formWrapper {
  display: grid;
  gap: 1.5rem;
}

.splitFormSection {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
}

.iconTitle {
  // font-size: 0.875rem;
  // font-weight: 500;
  // color: #787878;
  padding-bottom: 0.5rem;
}

.iconSets {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.iconSet {
  padding: 1rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-direction: column;
  border: solid 1px #cdcdcd;
  border-radius: 10px;
}

.selectedIconSet {
  border-color: var(--p-color-bg-primary);
}

.icon {
  display: inline-flex;
  width: 1.5rem;
  height: 1.5rem;
}
